@use "../abstracts/" as *;

.close-bar {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  div {
    position: absolute;
    top: 50%;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: map-get($orange, 500);
    transform: rotate(45deg);
    @include transition(0.4s, ease);
    &:last-child {
      transform: rotate(-45deg);
    }
  }
  &:hover div {
    transform: rotate(0);
  }
}
