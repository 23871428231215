@use "../abstracts/" as *;

/*============================================
  slider css start
==============================================*/

.slider {
  background-color: map-get($white, 200);
  .slider-item {
    display: flex;
    align-items: center;
    @include xs {
      height: auto;
      padding: 100px 0;
    }
    @include md {
      height: 600px;
    }
    @include lg {
      height: 750px;
    }
    .row {
      flex-direction: column-reverse;
      @include md {
        flex-direction: row;
      }
    }
    .content {
      padding: 0 25px;
      h4 {
        color: map-get($black, 300);
        font-weight: 600;
        margin: 20px 0;
        font-size: toRem(22);
        @include lg {
          margin: 30px 0;
          font-size: toRem(24);
        }
      }
      h2 {
        color: map-get($black, 100);
        font-weight: 700;
        margin: 20px 0;
        font-size: toRem(34);
        @include md {
          font-size: toRem(50);
          margin: 30px 0;
        }
        @include xl {
          font-size: toRem(70);
          margin: 50px 0;
        }
      }
      p {
        margin-bottom: 40px;
      }
    }
    .img {
      max-width: 339px;
      height: 250px;
      margin: auto;
      padding: 15px;

      @include md {
        height: 350px;
      }
      @include lg {
        height: 399px;
      }
    }
  }
  .swiper-button-next {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: map-get($white, 100);
    color: map-get($black, 100);
    box-shadow: $boxShadow;
    border-radius: 50%;
    right: -40px;
    visibility: hidden;
    @include transition(0.5s, ease);
    &::after {
      font-size: 13px;
    }
    &:hover {
      background-color: map-get($orange, 500);
      color: map-get($white, 100);
    }
  }

  .swiper-button-prev {
    @extend .swiper-button-next;
    left: -40px;
  }
  &:hover .swiper-button-next {
    right: 10px;
    visibility: visible;
  }
  &:hover .swiper-button-prev {
    left: 10px;
    visibility: visible;
  }
  .swiper-pagination-bullet {
    padding: 8px;
  }
  .swiper-pagination-bullet-active {
    background-color: map-get($orange, 500) !important;
  }
}

/*============================================
  slider css end
==============================================*/

/*============================================
 furniture slider css start
==============================================*/

.furniture-slider {
  padding: 100px 0;
  .item {
    border-radius: 50%;
    overflow: hidden;
    height: 180px;
    width: 180px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid map-get($black, 300);
    flex-direction: column;
    img {
      width: 70px;
      height: 72px;
    }
    h4 {
      color: map-get($black, 300);
    }
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
  }
}

/*============================================
 furniture slider css end
==============================================*/

/*============================================
  products css start
==============================================*/

.products {
  .products-nav ul {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: center;
    li {
      cursor: pointer;
      font-weight: 600;
      font-size: 17px;
      color: map-get($black, 300);
      @include transition(0.4s, ease);
      @include max-sm {
        font-size: 15px;
      }
      &.clicked {
        color: map-get($orange, 500);
      }
      &:hover {
        color: map-get($orange, 500);
      }
      &:not(:first-child) {
        margin-left: 23px;
        @include max-sm {
          margin-left: 12px;
        }
      }
    }
  }
}

/*============================================
  products css end
==============================================*/

/*============================================
  ad css start
==============================================*/

.ad {
  a {
    color: black;
  }
  .inner-ad {
    position: relative;
    padding: 20px;
    .caption {
      // background-color: black;
      padding: 40px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      &.two {
        align-items: flex-start;
      }
      h3 {
        font-weight: 600;
        font-size: toRem(25);
        line-height: 35px;
      }
    }
  }
}

/*============================================
  ad css end
==============================================*/

/*============================================
  news start
==============================================*/
.news {
  .news-item {
    h3 {
      margin: 10px 0;
      a {
        color: map-get($black, 100);
      }
    }
    .content {
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: toRem(13);
        color: map-get($black, 100);
        font-weight: 600;
        margin-bottom: 10px;
        a {
          color: map-get($black, 100);
        }
        p {
          margin: 0;
        }
      }
      p {
        margin-bottom: 25px;
      }
    }
  }
  .swiper-button-next {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: map-get($white, 100);
    color: map-get($black, 100);
    box-shadow: $boxShadow;
    border-radius: 50%;
    right: -40px;
    visibility: hidden;
    @include transition(0.5s, ease);
    &::after {
      font-size: 13px;
    }
    &:hover {
      background-color: map-get($orange, 500);
      color: map-get($white, 100);
    }
  }

  .swiper-button-prev {
    @extend .swiper-button-next;
    left: -40px;
  }
  &:hover .swiper-button-next {
    right: 10px;
    visibility: visible;
  }
  &:hover .swiper-button-prev {
    left: 10px;
    visibility: visible;
  }
}
/*============================================
  news end
==============================================*/
