@use "../abstracts" as a;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-style: normal;
  @include a.color(a.$black, 300);
  font-family: "open sans";
  font-size: a.toRem(16);
  font-weight: 400;
  line-height: a.toRem(26);
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
  height: 100%;
}

section {
  padding: 100px 0;
}

aside {
  z-index: 99;
}

.loading {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  h2 {
    font-weight: 500;
    color: rgb(54, 54, 54);
  }

  .loading-spin {
    width: 30px;
    height: 30px;
    border: 5px solid orange;
    border-radius: 50%;
    border-top: 5px solid transparent;
    animation: spin 2s linear infinite;
  }
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
