.section-title {
  text-align: center;
  margin-bottom: 42px;
  h2 {
    font-size: 36px;
    margin-bottom: 15px;
    font-weight: 700;
    text-transform: capitalize;
  }
}
