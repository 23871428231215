@use "../abstracts/" as *;

.products-item {
  text-align: center;
  h4 {
    a {
      color: map-get($black, 300);
      font-size: toRem(16);
      font-weight: 600;
      text-transform: capitalize;
    }
  }
  figure {
    position: relative;
    overflow: hidden;

    .action-buttons {
      position: absolute;
      top: 20px;
      right: 10px;
      .button-action {
        position: relative;
        .tooltip {
          visibility: hidden;
          // width: 100px;
          white-space: nowrap;
          background-color: black;
          color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 2px 10px;
          position: absolute;
          z-index: 1;
          // top: -5px;
          text-transform: capitalize;
          right: 110%;
          font-size: toRem(11);
          &::after {
            content: " ";
            position: absolute;
            top: 50%;
            left: 100%; /* To the right of the tooltip */
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent transparent black;
          }
        }
        &:hover .tooltip {
          visibility: visible;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &:not(:first-child) {
          margin-top: 35px;
          opacity: 0;
          @include transition(0.5s, ease);
        }
        button {
          background: none;
          border: none;
          font-size: toRem(15);
          padding: 8px;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          background-color: map-get($white, 100);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          @include transition(0.5s, ease);
          &:hover {
            background-color: map-get($orange, 500);
            color: map-get($white, 100);
          }
        }
      }
    }

    &:hover .button-action:not(:first-child) {
      margin-top: 0;
      opacity: 1;
    }

    .options {
      position: absolute;
      left: 50%;
      bottom: -44px;
      width: 80%;
      height: 44px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      transform: translateX(-50%);
      text-transform: capitalize;
      color: map-get($black, 100);
      background-color: map-get($white, 100);
      @include transition(0.5s, ease);

      &.isDisabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
      }
      svg {
        margin-right: 5px;
      }
      &:hover {
        color: map-get($orange, 500);
      }

      @include max-sm {
        position: static;
        transform: translateX(0);
        height: 30px;
        margin: auto;
        background-color: map-get($orange, 500);
        border-radius: 5px;
        color: map-get($white, 100);
        &:hover {
          background-color: map-get($white, 100);
          border: 1px solid map-get($orange, 500);
        }
      }
    }

    &:hover .options {
      bottom: 20px;
    }

    .discount {
      position: absolute;
      left: 10px;
      top: 20px;
      font-size: 12px;
      color: map-get($white, 100);
      background-color: map-get($red, 400);
      padding: 0 6px;
      border-radius: 5px;
    }

    // make responsive

    @include max-sm {
      overflow: none;
      .action-buttons {
        position: static;
        display: flex;
        justify-content: center;
        .button-action {
          &:not(:first-child) {
            margin-top: 0;
            opacity: 1;
          }
        }
      }
    }
  }

  .content {
    margin-top: 22px;
    a {
      @include transition(0.5s, ease);
      &:hover {
        color: map-get($orange, 500);
      }
    }
    @include xs {
      margin-top: 10px;
    }
    .normal-prize {
      color: map-get($black, 100);
      font-weight: 600;
    }
    .old-prize {
      margin-right: 15px;
      color: map-get($black, 500);
    }
  }
}
