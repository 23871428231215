@use "../abstracts/" as *;

.counter {
  background-color: map-get($white, 200);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90px;
  border-radius: 5px;
  margin-top: 10px;
  button {
    background: none;
    border: none;
    font-size: 13px;
    cursor: pointer;
  }
}
