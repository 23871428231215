@use "../abstracts/" as *;

.error {
  .inner-error {
    text-align: center;
    padding: 15px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.986);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    figure {
      margin-bottom: 22px;
      max-height: 115px;
      padding: 10px;
    }
    h2 {
      margin-bottom: 10px;
      @include max-sm {
        font-size: toRem(19);
      }
    }
    p {
      margin-bottom: 15px;
      @include max-sm {
        font-size: toRem(15);
      }
    }
  }
}
