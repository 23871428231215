@use "../abstracts/" as *;

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: map-get($blue, 200);
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  z-index: 98;
  .nav {
    display: flex;
    justify-content: space-between;
    button {
      background: none;
      border: none;
      &:hover a {
        color: map-get($orange, 500);
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: map-get($white, 100);
        @include transition(0.4s, ease);
        svg {
          font-size: 20px;
        }
        span {
          font-size: 10px;
          margin-top: 2px;
        }
      }
    }
    .bottom-cart-button {
      position: relative;
      span {
        &:last-child {
          font-size: 12px;
          background-color: hsl(26deg, 100%, 51%);
          color: hsl(0deg, 0%, 100%);
          padding: 2px 5px;
          border-radius: 50%;
          position: absolute;
          top: -5px;
          left: 75%;
        }
      }
    }
  }
  @include lg {
    display: none;
  }
}
