@use "../abstracts" as *;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: raleway;
  @include color($black, 100);
}
